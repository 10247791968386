import Error404Page from 'core/layouts/error-pages/Error404Page';
import { lazy, Suspense } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import DataLoader from 'ui/components/data-loader/DataLoader';

import SidebarLayout from '../../layouts/components/sidebar-layout/SidebarLayout';
import ErrorPage from '../../layouts/error-pages/ErrorPage';
import MustBeLoggedIn from '../protected-routes/must-be-logged-in/MustBeLoggedIn';
import { applicationRoutes } from './applicationRoutes';

const DashboardNew = lazy(
  () => import("app-sections/admin/dashboard-new/Dashboard")
);
const Applications = lazy(
  () => import("app-sections/admin/applications/Applications")
);
const EditAdmin = lazy(
  () => import("app-sections/admin/admin-profiles/edit-admin/EditAdmin")
);
const CreateAdmin = lazy(
  () => import("app-sections/admin/admin-profiles/create-admin/CreateAdmin")
);
const AdminProfiles = lazy(
  () => import("app-sections/admin/admin-profiles/AdminProfiles")
);
const UserProfiles = lazy(
  () => import("app-sections/admin/user-profiles/UserProfiles")
);

const ShowUser = lazy(
  () => import("app-sections/admin/user-profiles/show-user/ShowUser")
);
// const TeamAssignments = lazy(
//   () =>
//     import(
//       "app-sections/admin/dashboard/components/team-assignments/TeamAssignments"
//     )
// );
// const MyTasks = lazy(
//   () => import("app-sections/admin/dashboard/components/my-tasks/MyTasks")
// );
// const Dashboard = lazy(() => import("app-sections/admin/dashboard/Dashboard"));

const Notifications = lazy(
  () => import("app-sections/common/notifications/Notifications")
);

export const adminGeneralRoutes: RouteObject[] = [
  {
    path: "/admin",
    element: (
      <MustBeLoggedIn role="admin">
        <SidebarLayout />
      </MustBeLoggedIn>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: (
          <Suspense fallback={<DataLoader />}>
            <DashboardNew />
          </Suspense>
        ),
        children: [
          {
            path: "team-assignment",
            element: <Navigate replace to="/admin" />,
          },
        ],
      },
      {
        path: "dashboard-new",
        element: <Navigate replace to="/admin" />,
      },
      {
        path: "applications",
        element: (
          <Suspense fallback={<DataLoader />}>
            <Applications />
          </Suspense>
        ),
      },
      {
        path: "applications/:id",
        children: applicationRoutes,
      },
      {
        path: "user-profiles",
        element: (
          <Suspense fallback={<DataLoader />}>
            <UserProfiles />
          </Suspense>
        ),
      },
      {
        path: "user-profiles/:id",
        element: (
          <Suspense fallback={<DataLoader />}>
            <ShowUser />
          </Suspense>
        ),
      },
      {
        path: "admin-profiles",
        element: (
          <Suspense fallback={<DataLoader />}>
            <AdminProfiles />
          </Suspense>
        ),
      },
      {
        path: "admin-profiles/add-new",
        element: (
          <Suspense fallback={<DataLoader />}>
            <CreateAdmin />
          </Suspense>
        ),
      },
      {
        path: "admin-profiles/:id/edit",
        element: (
          <Suspense fallback={<DataLoader />}>
            <EditAdmin />
          </Suspense>
        ),
      },
      {
        path: "notifications",
        element: (
          <Suspense fallback={<DataLoader />}>
            <Notifications />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "/404",
    element: <Error404Page />,
  },
];
