import { getApplicationById } from 'core/api/applications';
import { useApplicationsStore } from 'core/store/applications';
import { useAuthStore } from 'core/store/auth';
import BreadCrumbs from 'ui/components/breadcrumbs/BreadCrumbs';
import { isAdmin } from 'ui/helpers/users';

import { Box, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import ApplicationStateBadge from '../application-state-badge/ApplicationStateBadge';
import DebugLink from '../debug-link/DebugLink';
import CheckSwoButton from '../check-swo-button/CheckSwoButton';

export default function DetailsTitle() {
  const { activeApplicationId } = useApplicationsStore();
  const { currentUser } = useAuthStore();

  const applicationQueryKey = ["applications", activeApplicationId];

  const { isLoading, data } = useQuery({
    queryKey: applicationQueryKey,
    queryFn: () => getApplicationById(activeApplicationId),
    enabled: activeApplicationId !== "",
  });

  const applicationNumber = data?.certificatePermitNumber || data?.id;

  return (
    <Box>
      {currentUser && (
        <Box
          sx={{ display: "flex", alignItems: "center", justifyItems: "center" }}
        >
          <BreadCrumbs
            data={[
              {
                label: "My Applications",
                link: `${
                  isAdmin(currentUser.userProfile.roles) ? "/admin" : ""
                }/applications`,
              },
              {
                label: `Application Number ${applicationNumber}`,
                link: "",
              },
            ]}
          />
          <DebugLink />
          <CheckSwoButton />
        </Box>
      )}
      <Box
        mt={-1}
        mb={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Box mr={4}>
          <Typography
            variant="h1"
            component="h1"
            className="with-indicator"
            sx={{ mt: 3 }}
          >
            Application Number {applicationNumber}
          </Typography>
        </Box>
        {!isLoading && data && <ApplicationStateBadge appState={data.state} />}
      </Box>
    </Box>
  );
}
