import { DataParts } from 'app-sections/user/applications/steps/step4/config';
import { ApiCollectionResponse, ApiPayResponse, Application, LogItem, NewApplication, Params } from 'types';

import { getCollectionResponse, request } from '../config';

const APP_URL = 'applications.json';
const APP_BY_ID_URL = 'applications';

export function createApplication(application: NewApplication): Promise<Application> {
  return request(
    {
      url: APP_URL,
      method: 'POST',
      data: { application },
    },
    (data) => data.model
  );
}

export function getApplications(data: Params, signal?: AbortSignal): Promise<ApiCollectionResponse<Application>> {
  return request(
    {
      url: APP_URL,
      method: 'GET',
      params: { ...data, expand: true, notStates: 'draft_deleted_by_client' },
      signal,
    },
    (data) => {
      return getCollectionResponse<Application>(data);
    }
  );
}

export function getApplicationById(id: string): Promise<Application> {
  return request(
    {
      url: `${APP_BY_ID_URL}/${id}.json`,
      method: 'GET',
      data: {},
    },
    (data) => data.model
  );
}

export function getApplicationByNumber(certificateNumber: string): Promise<Application> {
  return request(
    {
      url: `${APP_BY_ID_URL}/${certificateNumber}/co_number.json`,
      method: 'GET',
      data: {},
    },
    (data) => data.model
  );
}

export function saveStep<T>(id: string, step: number, lastValidStep: number, data: T): Promise<Application> {
  return request(
    {
      url: `${APP_BY_ID_URL}/${id}.json`,
      method: 'PATCH',
      data: {
        application: {
          ...data,
          lastSavedStep: step,
          currentStep: lastValidStep,
        },
      },
    },
    (data) => data.model
  );
}

export function manualSwoCheck(id: string): Promise<Params> {
  return request(
    {
      url: `${APP_BY_ID_URL}/${id}/check-swo.json`,
      method: 'PATCH',
      data: {},
    },
    () => {
      return {
        id: id,
        isChecked: null,
        isLoading: true,
      };
    }
  );
}

export function getPayUrl(id: string): Promise<ApiPayResponse> {
  return request({
    url: `applications/${id}/pay.json`,
    method: 'POST',
  });
}

export function cancelApplication(applicationId: string): Promise<Application> {
  return request(
    {
      url: `${APP_BY_ID_URL}/${applicationId}/cancel.json`,
      method: 'PATCH',
    },
    (data) => data.model
  );
}

export function reopenApplication(applicationId: string, reopenReason: string): Promise<Application> {
  return request(
    {
      url: `${APP_BY_ID_URL}/${applicationId}/reopen.json`,
      method: 'PATCH',
      data: { application: { reopenReason } },
    },
    (data) => data.model
  );
}

export function updateApplication(
  applicationId: string,
  changeReason: string,
  fieldChanged: keyof DataParts,
  occupantLoad: string,
  data: Params
): Promise<LogItem> {
  return request(
    {
      url: `${APP_BY_ID_URL}/${applicationId}/update-load.json`,
      method: 'PATCH',
      data: {
        application: {
          fieldChanged,
          changeReason,
          [fieldChanged]: data,
          occupantLoad,
        },
      },
    },
    (data) => data.model.change
  );
}
