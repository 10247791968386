import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

/**
 * Fireworks type is available for the applicants to apply from last Monday of May month and until July 1st and expiration of the CO will be July 5th
 * @returns boolean
 */
export const isFireworksAllowed = (): boolean => {
  // Initialize to the last day of May for the current year
  let dateStart = dayjs().month(4).date(31); // May is month 4 (0-indexed)

  // Find the last Monday in May
  // If the day is already Monday, it will stay the same; otherwise, it will go back to the last Monday
  dateStart = dateStart.day(dateStart.day() >= 1 ? 1 : -6);

  const dateEnd = dayjs().month(6).date(1); // July 1st
  return dayjs().isBetween(dateStart, dateEnd, 'day', '[)');
};
